import * as React from "react";
import {projects, Release} from "../data";
import {projectAnchor} from "../helpers";

const Discography = (): JSX.Element => {
  type ReleaseAndBand = Release & {band: string};
  let discog: Array<ReleaseAndBand> = [];

  Object.keys(projects).map((a) => projects[a].releases?.map((x) => discog.push({band: a, ...x})));
  discog = discog.sort((a, b) => (a.band > b.band ? 1 : -1)).sort((a, b) => (a.releaseDate > b.releaseDate ? 1 : -1));

  return (
    <div>
      <h2>Discography</h2>
      See also <a href="https://www.discogs.com/artist/6519777-Patrick-Michot">Patrick Michot Discogs Page</a>
      <table className="discography-table">
        <thead>
          <tr>
            <td>Release Date (yyyy/mm/dd)</td>
            <td>Project</td>
            <td>Album Title</td>
            <td>Contribution</td>
          </tr>
        </thead>
        <tbody>
          {discog.map((release: ReleaseAndBand) => (
            <tr key={release.bandcamp}>
              <td>{release.releaseDate}</td>
              <td>{projectAnchor(release.band)}</td>
              <td>
                <a href={release.bandcamp}>{release.title}</a>
              </td>
              <td>{release.contribution}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Discography;
