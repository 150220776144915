type Link = {title: string; url: string};

export type Project = {
  genre: string;
  releases?: Array<Release>;
  description?: string;
  links?: Array<Link>;
  press?: Array<Article>;
  awards?: Array<string>;
  years: string;
};

export type Release = {
  title: string;
  releaseDate: string;
  bandcamp?: string;
  spotify?: string;
  albumId?: string;
  bcTitle?: string;
  contribution?: string;
};

export type Article = {
  title: string;
  url: string;
};

const guest = "guest (non-active member)";

export const projects: Record<string, Project> = {
  "Special Agent": {
    genre: "post-industrial / digital hxc",
    description: "egodeath driven post-industrial hxc cyberpunk nervous breakdown showmanship",
    links: [
      {title: "Facebook", url: "https://facebook.com/specialagenttx"},
      {title: "Instagram", url: "https://instagram.com/specialagenttx"},
      {title: "Bandcamp", url: "https://specialagenttx.bandcamp.com"},
    ],
    years: "(2022 - present)",
    releases: [
      {
        title: "Shadow of a Doubt",
        releaseDate: "2023/03/02",
        bandcamp: "https://specialagenttx.bandcamp.com/album/shadow-of-a-doubt",
        albumId: "1883599207",
        bcTitle: "Shadow of a Doubt by Special Agent",
        contribution: "All digital instruments, mixing",
      },
    ],
  },
  "Oil Spill": {
    genre: "Black metal / Sludge metal / Death metal",
    description:
      "Scorching Texas Black Metal band of Elaine Hubbard, Patrick Michot, Miguel Espinel, Charles Knowles, and Imran Syed.",
    links: [
      {title: "Facebook", url: "https://facebook.com/oilspilltx"},
      {title: "Instagram", url: "https://instagram.com/oilspilltx"},
      {title: "Bandcamp", url: "https://oilspilltx.bandcamp.com"},
    ],
    releases: [
      {
        title: "Ashlands",
        releaseDate: "2020/04/03",
        bandcamp: "https://oilspilltx.bandcamp.com/album/ashlands",
        albumId: "1490571780",
        bcTitle: "Ashlands by Oil Spill",
        contribution: "Electric guitar, composition",
      },
    ],
    press: [
      {
        title: "Cvlt Nation: 5 Dismal & Twisted Young Bands that Kick Ass!",
        url: "https://cvltnation.com/5-dismal-twisted-young-bands-that-kick-ass/",
      },
      {
        title: "May's Murderous Musical Maelstrom Spotify Playlist From THE BLACK DAHLIA MURDER's Trevor Strnad",
        url: "https://metalinjection.net/editorials/the-obituarist/mays-murderous-musical-maelstrom-spotify-playlist-from-the-black-dahlia-murders-trevor-strnad",
      },
      {
        title: "Astral Noize: Under the Radar: 17 of the Best Underground Black Metal Projects",
        url: "https://astralnoizeuk-com.cdn.ampproject.org/v/s/astralnoizeuk.com/2020/05/17/under-the-radar-18-of-the-best-underground-black-metal-projects/amp/?amp_gsa=1&amp_js_v=a6&usqp=mq331AQIKAGwASCAAgM%3D#ampshare=https%3A%2F%2Fastralnoizeuk.com%2F2020%2F05%2F17%2Funder-the-radar-18-of-the-best-underground-black-metal-projects%2F",
      },
      {
        title: "No Clean Singing (review of Ashlands EP)",
        url: "https://www.nocleansinging.com/2020/04/15/overflowing-streams-oil-spill-protosequence-centinex-devangelic-smoke-mirrors-varaha-horn-cauldron-black-ram-lord-mantis-the-lifted-veil/",
      },
      {
        title: "Fucked By Noise (review of Ashlands EP)",
        url: "https://fuckedbynoise.blogspot.com/2020/04/oil-spill-ashlands.html",
      },
    ],
    years: "(2019 - present)",
  },
  seres: {
    genre: "Synth punk / Zolo",
    description: "Angular synth punk / zolo band of Alfonso Callejas, Patrick Michot, Pat Troester, and Chris Holmes.",
    links: [
      {title: "Bandcamp", url: "https://somoseres.bandcamp.com"},
      {title: "Facebook", url: "https://facebook.com/somoseres"},
      {title: "Instagram", url: "https://www.instagram.com/somoseresbanda"},
    ],
    releases: [
      {
        title: "SRS02",
        releaseDate: "2020/05/29",
        bandcamp: "https://somoseres.bandcamp.com/album/srs02-2",
        bcTitle: "SRS02 by seres",
        albumId: "3154276871",
        contribution: "Electric guitar, composition",
      },
      {
        title: "seres",
        releaseDate: "2015/11/06",
        bandcamp: "https://somoseres.bandcamp.com/album/seres",
        bcTitle: "seres by seres",
        albumId: "3525921804",
        contribution: "Electric guitar",
      },
      {
        title: "ES E.P.",
        releaseDate: "2015/04/06",
        bcTitle: "ES E.P. by seres",
        bandcamp: "https://somoseres.bandcamp.com/album/es-e-p",
        albumId: "219777671",
        contribution: "Electric guitar",
      },
      {
        title: "Seres Macaroni Island 03/06/15",
        releaseDate: "2015/03/15",
        bcTitle: "Seres Macaroni Island 03/06/15 by seres",
        bandcamp: "https://somoseres.bandcamp.com/album/seres-macaroni-island-03-06-15",
        albumId: "6597789",
        contribution: "Electric guitar",
      },
    ],
    press: [
      {
        title: "Selected Goosebumps (review of SRS02)",
        url: "https://gbumps.blog/2021/10/30/seres-srs02-2020/",
      },
      {
        title: "Dallas Observer: seres Wins Best Experimental Act in 2020",
        url: "https://www.dallasobserver.com/music/a-complete-list-of-the-2020-dallas-observer-music-awards-winners-11974584",
      },
      {
        title: "Dallas Observer:  Seres Are Spanish-Singing, Latex-Wearing Misfits from Another Time and Place",
        url: "https://www.dallasobserver.com/music/seres-are-spanish-singing-latex-wearing-misfits-from-another-time-and-place-8056760",
      },
      {
        title: "Dallas Observer: Synth-Punk Band Seres Embraces the Pandemic With a New Album ",
        url: "https://www.dallasobserver.com/music/punk-band-seres-has-a-new-release-11913824",
      },
    ],
    awards: [
      "Dallas Observer Music Awards 2020 - Best Experimental/Noise Act Winner",
      "Dallas Observer Music Awards 2017 - Best Live Act Nominee",
    ],
    years: "(2015 - present)",
  },
  Duskseeker: {
    genre: "Experimental / Black Metal / Ambient / Progressive",
    description: "Symphonic/experimental black metal musique concréte atmospheric vortex OST work of Patrick Michot.",
    links: [
      {title: "Facebook", url: "https://www.facebook.com/dvskseeker"},
      {title: "Bandcamp", url: "https://duskseeker.bandcamp.com"},
    ],
    releases: [
      {
        title: "Spiralscape",
        releaseDate: "2021/07/02",
        bandcamp: "https://duskseeker.bandcamp.com/album/spiralscape",
        bcTitle: "Spiralscape by Duskseeker",
        albumId: "3844885827",
        contribution: "All composition, performance, recording, and mixing",
      },
      {
        title: "Black Wolf Nightmare",
        releaseDate: "2017/10/08",
        bcTitle: "Black Wolf Nightmare by Duskseeker",
        bandcamp: "https://duskseeker.bandcamp.com/album/black-wolf-nightmare",
        albumId: "1768858707",
        contribution: "All composition, performance, recording, and mixing",
      },
      {
        title: "imperial_demos",
        releaseDate: "2015/09/01",
        bcTitle: "imperial_demos by Duskseeker",
        bandcamp: "https://duskseeker.bandcamp.com/album/imperial-demos",
        albumId: "898397163",
        contribution: "All composition, performance, recording, and mixing",
      },
    ],
    press: [
      {
        title: "Cvlt Nation: Enter the atmospheric vortex DUSKSEEKER 'Spiralscape' Symphonic Black Metal Universe!",
        url: "https://cvltnation.com/duskseeker-spiralscape-symphonic-black-metal-universe/",
      },
      {
        title: "No Clean Singing (review of Spiralscape)",
        url: "https://www.nocleansinging.com/2021/07/04/shades-of-black-part-2-morast-antimonument-iskalde-morket-duskseeker-bezdech/",
      },
      {
        title: "Metal Purgatory Media (review of Spiralscape)",
        url: "https://metalpurgatorymedia.com/2021/08/17/duskseeker-spiralscape/",
      },
    ],
    years: "(2014 - present)",
  },
  K0m0d0tr0n: {
    genre: "Electronic / Industrial / Ambient",
    description: "Electronic music by Patrick Michot.",
    links: [
      {title: "Bandcamp", url: "https://k0m0d0tr0n.bandcamp.com"},
      {title: "Facebook", url: "https://www.facebook.com/k0m0d0tr0n"},
    ],
    releases: [
      {
        title: "K0m0d0tr0n",
        releaseDate: "2018/07/27",
        bandcamp: "https://k0m0d0tr0n.bandcamp.com/album/k0m0d0tr0n",
        bcTitle: "K0m0d0tr0n by K0m0d0tr0n",
        albumId: "1092154002",
        contribution: "All composition, performance, recording, and mixing",
      },
    ],
    years: "(2017 - present)",
  },
  Oigsfa: {
    genre: "Experimental",
    description: "Experimental tape manipulation / granular synthesis work of Patrick Michot and Alfonso Callejas.",
    links: [{title: "Bandcamp", url: "https://oigsfa.bandcamp.com"}],
    releases: [
      {
        title: "Music For Opening Presents",
        releaseDate: "2020/12/12",
        bandcamp: "https://oigsfa.bandcamp.com/album/music-for-opening-presents",
        bcTitle: "Music For Opening Presents by Oigsfa",
        albumId: "852174588",
        contribution: "Computers, composition, performance, recording, and mixing",
      },
      {
        title: "oigsfa_1-4.wav",
        releaseDate: "2018/10/08",
        bandcamp: "https://oigsfa.bandcamp.com/album/oigsfa-1-4-wav",
        bcTitle: "oigsfa_1-4.wav by Oigsfa",
        albumId: "3295201726",
        contribution: "Computers, composition, performance, recording, and mixing",
      },
    ],
    years: "(2017 - present)",
  },
  "Royal Academia": {
    genre: "Classical / Improv / Performance Art",
    description:
      "Satirical, classical and electronic performance art by Patrick Michot, Sarah Ruth Alexander, and Alfonso Callejas.",
    links: [
      {
        title: "Facebook",
        url: "https://www.facebook.com/RoyalAcademiaCordofonica/",
      },
      {title: "Bandcamp", url: "https://royalacademia.bandcamp.com"},
    ],
    releases: [
      {
        title: "Casio Choir",
        releaseDate: "2018/01/31",
        bandcamp: "https://royalacademia.bandcamp.com/album/casio-choir",
        bcTitle: "Casio Choir by The Royal Academia Cordofonica",
        albumId: "2073767252",
        contribution: "Keyboards, vocals, composition (improv), performance, and recording.",
      },
      {
        title: "Key of Seafood",
        releaseDate: "2018/06/10",
        bandcamp: "https://royalacademia.bandcamp.com/album/key-of-seafood",
        bcTitle: "Key of Seafood by The Royal Academia Cordofonica",
        albumId: "3298135386",
        contribution: "Nylon string guitar, vocals, composition, performance",
      },
      {
        title: "Bullfrog Kingdom",
        releaseDate: "2016/05/26",
        bandcamp: "https://royalacademia.bandcamp.com/album/bullfrog-kingdom",
        bcTitle: "Bullfrog Kingdom by The Royal Academia Cordofonica",
        albumId: "3860015737",
        contribution: "Nylon string guitar, charango, vocals, composition, performance",
      },
      {
        title: "The E",
        releaseDate: "2016/05/21",
        bandcamp: "https://royalacademia.bandcamp.com/album/the-e",
        bcTitle: "The E by The Royal Academia Cordofonica",
        albumId: "444538250",
        contribution: "Nylon string guitar, charango, bowls, vocals, composition, performance",
      },
    ],
    years: "(2016 - present)",
  },
  "Les Freres Michot": {
    genre: "Cajun",
    description: "Traditional Cajun music.  Formed in 1986.",
    links: [
      {title: "Official Website", url: "https://tcmichot.wixsite.com/lesfreresmichot"},
      {title: "Facebook", url: "https://www.facebook.com/lesfreresmichot/"},
    ],
    releases: [
      {
        title: "La Caroline",
        releaseDate: "2008",
        contribution: "Acoustic guitar",
      },
    ],
    years: "(2003 - present)",
  },
  Starfruit: {
    genre: "Avant-Pop / Rock",
    description: "Entity/ensemble formed in 2016 by singer/composer/multi-instrumentalist Ava W Boehme (they/she)",
    links: [
      {title: "Facebook", url: "https://www.facebook.com/starfruitohyeah/"},
      {title: "Bandcamp", url: "https://starfruit.bandcamp.com"},
    ],
    releases: [
      {
        title: "Wild West Combat",
        releaseDate: "2020/01/24",
        bandcamp: "https://starfruit.bandcamp.com/album/wild-west-combat",
        contribution: "Electric guitar",
        albumId: "194666673",
      },
    ],
    press: [
      {
        title: " Dallas’ Newest Pop Band, Starfruit, Strives to Make Music a Baby Would Like",
        url: "https://www.dallasobserver.com/music/starfruit-makes-synth-pop-but-you-wont-spot-any-synthesizers-on-stage-9300996",
      },
    ],
    awards: ["Dallas Observer Music Awards 2017 - Best Pop Act Nominee"],
    years: "(2018 - 2019)",
  },
  "Patrick Michot": {
    genre: "Lots of them",
    description: "General solo work of Patrick Michot.",
    links: [
      {title: "Instagram", url: "https://instagram.com/k0m0d0tr0n"},
      {title: "Bandcamp", url: "https://patrickmichot.bandcamp.com"},
    ],
    releases: [
      {
        title: "2004 - 2007",
        releaseDate: "2019/10/17",
        bandcamp: "https://patrickmichot.bandcamp.com/album/2004-2007",
        bcTitle: "2004 - 2007 by Patrick Michot",
        albumId: "1502054447",
        contribution: "All composition, performance, recording, and mixing",
      },
      {
        title: "Mighty Ascension Original Soundtrack",
        releaseDate: "2023/03/08",
        bandcamp: "https://patrickmichot.bandcamp.com/album/mighty-ascension-original-soundtrack",
        bcTitle: "Mighty Ascension Original Soundtrack by Patrick Michot",
        albumId: "664976343",
        contribution: "All composition, performance, recording, and mixing",
      },
    ],
    years: "(1990 - present)",
  },
  "The Rayo Brothers": {
    genre: "Folk rock / Bluegrass / Country",
    description: "Folk rock / bluegrass / country band from Lafayette, LA.",
    links: [
      {title: "Official Website", url: "https://www.rayobrothers.com/"},
      {title: "Facebook", url: "https://www.facebook.com/rayobrothers"},
      {title: "Instagram", url: "https://www.instagram.com/rayobrothers"},
    ],
    releases: [
      {
        title: "Reaux EP",
        releaseDate: "2013/03/04",
        contribution: "Acoustic bass",
        spotify: "https://open.spotify.com/embed/album/4QbF7bN13UgxSQU99Rl23i?utm_source=generator",
      },
    ],
    years: "(2011 - 2013)",
  },
  Asukubus: {
    genre: "Experimental",
    description:
      "Asukubus is an experimental collective featuring: Wyatt Rosser, Sarah Ruth, Aaron Gonzalez, Stefan Gonzalez, Hannah Wier, Kennedy Ashlyn, Ethan Berman, Katie Reese, Tamara Cauble Brown, Patrick Michot, Ernesto Montiel, Llandon Broadhead, Dustin Sheehan, Brandon Young, Cesar Velasco",
    links: [
      {title: "Bandcamp", url: "https://asukubus.bandcamp.com"},
      {title: "Facebook", url: "https://www.facebook.com/asukubus"},
    ],
    releases: [
      {
        title: "Painful is Silence",
        releaseDate: "2017/12/21",
        bandcamp: "https://asukubus.bandcamp.com/album/painful-is-silence",
        contribution: "Cello",
        albumId: "3878556251",
      },
    ],
    awards: ["Dallas Observer Music Awards 2017 - Best Experimental/Noise Act Nominee"],
    years: guest,
  },
  "Rosegarden Funeral Party": {
    genre: "Goth Rock / New Wave / Post-Punk",
    description: "Dallas goth rock / new wave / post-punk",
    links: [
      {title: "Official Website", url: "https://www.rosegardenfuneralparty.com/"},
      {title: "Instagram", url: "https://www.instagram.com/rosegardenfuneralparty/"},
      {title: "Facebook", url: "https://www.facebook.com/rosegardenfuneralparty"},
    ],
    releases: [
      {
        title: "In The Wake of Fire",
        releaseDate: "2021/12/03",
        bandcamp: "https://rosegardenfuneralparty.bandcamp.com/album/in-the-wake-of-fire",
        contribution: "Nylon string guitar",
        albumId: "4257368416",
      },
      {
        title: "Take Cover Vol. 1",
        releaseDate: "2021/12/03",
        bandcamp: "https://rosegardenfuneralparty.bandcamp.com/album/take-cover",
        contribution: "Nylon string guitar",
        albumId: "2313414793",
      },
    ],
    years: guest,
  },
  Gort: {
    genre: "Black Metal / New Age",
    description: "Black metal by Jojo Nanez and friends.",
    // links: [{title: "Bandcamp", url: "https://gort.bandcamp.com/album"}],
    releases: [
      {
        title: " At the Cliffs • In the Pools • At the Vines",
        releaseDate: "2021/05/07",
        bandcamp: "https://gort.bandcamp.com/album/at-the-cliffs-in-the-pools-at-the-vines",
        contribution: "Nylon string guitar",
        albumId: "1978420007",
      },
    ],
    years: guest,
  },
  "Infernal Legions of Mordor": {
    genre: "Noisecore",
    description: "Absurdist noisecore from Denton, TX",
    releases: [
      {
        title: "311 Bygone Tales From The Realm Of Sauron",
        releaseDate: "2021/04/20",
        bandcamp: "https://infernallegionsofmordor.bandcamp.com/album/311-bygone-tales-from-the-realm-of-sauron",
        contribution: "Spoken word",
        albumId: "2165882351",
      },
    ],
    years: guest,
  },
  "The Water Templars": {
    genre: "Video Game Cover Music",
    description:
      "This super-mega-jumbo accoustic post modern hyper-progressive powerhouse duo will kick your ass with videogame and anime nostalgia.",
    links: [
      {title: "Facebook", url: "https://www.facebook.com/TheWaterTemplars/"},
      {title: "YouTube", url: "https://www.youtube.com/user/TheWaterTemplars"},
    ],
    years: "(2014 - present)",
  },
  "Yellow Sweat": {
    genre: "Experimental",
    description: "Experimental music duo of Patrick Michot and James Billeaudeau.",
    years: "(2005 - 2006)",
  },
};
