import * as React from "react";

const Contact = (): JSX.Element => (
  <>
    <h2>Contact</h2>
    <article>
      Email: <a href="mailto:pmichot@protonmail.com">pmichot@protonmail.com</a>
    </article>
  </>
);

export default Contact;
