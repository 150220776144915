import * as React from "react";
import {HashRouter as Router} from "react-router-dom";
import Footer from "./Footer";
import Main from "./Main";
import Nav from "./Nav";
import "./App.css";

const App = (): JSX.Element => (
  <>
    <h1>Patrick Michot</h1>
    <Router>
      <div>
        <header>
          <Nav />
        </header>
        <Main />
      </div>
    </Router>
    <hr />
    <Footer />
  </>
);

export default App;
