import * as React from "react";
import {HashLink} from "react-router-hash-link";
import {projects} from "../data";
import {projectAnchor} from "../helpers";

const About = (): JSX.Element => (
  <>
    <h2>About</h2>
    <div>
      <HashLink to="/about#projects">Projects</HashLink>
    </div>
    <div>
      <HashLink to="/about#awards">Awards</HashLink>
    </div>
    <article>Patrick Michot is a musician and programmer.</article>
    <h4 id="projects">Projects</h4>
    <span className="projects">
      <table>
        <thead>
          <tr>
            <td>Project</td>
            <td>Years Served</td>
          </tr>
        </thead>
        <tbody>
          {Object.keys(projects)
            .sort((a, b) => (projects[a].years > projects[b].years ? 1 : -1))
            .map((project) => (
              <tr key={project}>
                <td>{projectAnchor(project)}</td>
                <td>{projects[project].years}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </span>
    <h4 id="awards">Awards</h4>
    <span className="awards">
      <table>
        <thead>
          <tr>
            <td>Project</td>
            <td>Award</td>
          </tr>
        </thead>
        <tbody>
          {Object.keys(projects)
            .filter((project) => projects[project].awards)
            .map((project) =>
              projects[project].awards?.map((award) => (
                <tr key={award}>
                  <td>{projectAnchor(project)}</td>
                  <td>{award}</td>
                </tr>
              ))
            )}
        </tbody>
      </table>
    </span>
  </>
);

export default About;
