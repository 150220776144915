import * as React from "react";
import {Project} from "../../data";

type PageProps = {
  project: Project;
  projectName: string;
};

const Page = (props: PageProps): JSX.Element => (
  <>
    <h2>{props.projectName}</h2>
    <article>{props.project.description}</article>
    {props.project.links && (
      <div>
        <h4>Links</h4>
        {props.project.links.map((link) => (
          <>
            <a key={link.url} href={link.url}>
              {link.title}
            </a>
            <br />
          </>
        ))}
      </div>
    )}
    {props.project.releases?.find((release) => release.bandcamp || release.spotify) && <h4>Listen</h4>}
    {props.project.releases?.map(
      (release) =>
        (release.bandcamp && (
          <iframe
            key={release.albumId}
            title={release.title}
            className="bc-embed"
            src={`https://bandcamp.com/EmbeddedPlayer/album=${release.albumId}/size=large/bgcol=333333/linkcol=0f91ff/transparent=true/`}
            seamless
          >
            <a href={release.bandcamp}>{release.title}</a>
          </iframe>
        )) ||
        (release.spotify && (
          <iframe
            title={release.title}
            src={release.spotify}
            width="100%"
            height="380"
            frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          ></iframe>
        ))
    )}
  </>
);

export default Page;
