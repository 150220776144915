import * as React from "react";

const Hologramophone = (): JSX.Element => (
  <>
    <h2>Hologramophone</h2>
    <article>
      FM/stream radio show on <a href="https://www.kuzu.fm">KUZU</a> (LPFM community radio station in Denton, TX).
      Music, skits, sound collages, spoken words, and other sounds revolving around a singular theme (different theme
      per episode) so as to immerse the listener in that theme as curated by Patrick Michot.
    </article>
    <article>
      More info about <a href="https://www.kuzu.fm/programs/hologramophone">Hologramophone at kuzu.fm</a> or on{" "}
      <a href="https://www.facebook.com/hologramophoneradio">Facebook</a>.
    </article>
  </>
);

export default Hologramophone;
