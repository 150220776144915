import * as React from "react";
import {useState} from "react";
import {Link} from "react-router-dom";
import {nameToPath} from "./helpers";

type INavListItem = {
  abbr: string;
  name: string;
};

const getPath = (name: string): string => (name === "Home" ? "/" : `/${nameToPath(name)}`);

const Nav = (): JSX.Element => {
  const [active, setActive] = useState(false);

  const NavListItem = (props: INavListItem): JSX.Element => {
    const path = getPath(props.name);

    return (
      <>
        <abbr title={props.abbr}>
          <Link onClick={() => setActive(false)} to={path}>
            {props.name}
          </Link>
        </abbr>
      </>
    );
  };

  return (
    <>
      <div className="navbar">
        <NavListItem name="Home" abbr="where the heart is" />
        <div
          className="dropdown"
          onBlur={(e) => {
            if (e.relatedTarget === null) setActive(false);
          }}
          onFocus={() => setActive(true)}
        >
          <button className="dropbtn">Music</button>
          <div className={active ? "dropdown-content active" : "dropdown-content inactive"}>
            <NavListItem name="Duskseeker" abbr="weird black metal" />
            <NavListItem name="Oil Spill" abbr="slimy black metal" />
            <NavListItem name="Special Agent" abbr="egodeath cyberpunx" />
            <NavListItem name="seres" abbr="synth punk" />
            <NavListItem name="K0m0d0tr0n" abbr="electronic" />
            <NavListItem name="Oigsfa" abbr="experiments" />
            <NavListItem name="Royal Academia" abbr="classicalfuck" />
            <NavListItem name="Patrick Michot" abbr="solo" />
          </div>
        </div>
        <NavListItem name="Hologramophone" abbr="radio" />
        <NavListItem name="Memory Terminal Records" abbr="my record label" />
        <NavListItem name="Discography" abbr="discography" />
        {/* <NavListItem name="Pictures" abbr="photos of me" /> */}
        <NavListItem name="About" abbr="air movement"></NavListItem>
        <NavListItem name="Press" abbr="writings about me" />
        <NavListItem name="Contact" abbr="talk to me" />
      </div>
    </>
  );
};

export default Nav;
