import * as React from "react";
import {Route, Routes} from "react-router-dom";
import Home from "./Home";
import Hologramophone from "./Hologramophone";
import Pictures from "./Pictures";
import Press from "./Press";
import Contact from "./Contact";
import Page from "./Music/Page";
import About from "./About";
import Discography from "./Discography";
import {projects} from "../data";
import {nameToPath} from "../helpers";
import MemoryTerminalRecords from "./MemoryTerminalRecords";

const Main = (): JSX.Element => (
  <main>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/music" />
      {Object.keys(projects).map((project) => (
        <Route
          key={project}
          path={nameToPath(project)}
          element={<Page project={projects[project]} projectName={project} />}
        />
      ))}
      <Route path="/contact" element={<Contact />} />
      <Route path="/pictures" element={<Pictures />} />
      <Route path="/hologramophone" element={<Hologramophone />} />
      <Route path="/memoryterminalrecords" element={<MemoryTerminalRecords />} />
      <Route path="/press" element={<Press />} />
      <Route path="/about" element={<About />} />
      <Route path="/discography" element={<Discography />} />
    </Routes>
  </main>
);

export default Main;
