/* eslint-disable @typescript-eslint/no-require-imports, @typescript-eslint/no-var-requires */

import * as React from "react";
const me = require("../images/me.jpg");

const Home = (): JSX.Element => (
  <>
    {/* <article>Patrick Michot is a musician and programmer.</article> */}
    <img className="me" alt="me" src={me} />
  </>
);

export default Home;
