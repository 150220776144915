import * as React from "react";

const MemoryTerminalRecords = (): JSX.Element => (
  <>
    <h2>Memory Terminal Records</h2>
    <article>Memory Terminal Records is my record label. I put out lots of stuff.</article>
    <article>
      <a href="http://www.memoryterminal.com">Official Website</a>
    </article>
  </>
);

export default MemoryTerminalRecords;
