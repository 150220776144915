import * as React from "react";
import {Article, projects} from "../data";

const Press = (): JSX.Element => (
  <>
    <h2>Press</h2>
    <article className="press">
      {Object.keys(projects).map(
        (band) =>
          projects[band].press && (
            <div key={band}>
              <h4>{band}</h4>
              {projects[band].press?.map((article: Article) => (
                <li key={article.url}>
                  <a href={article.url}>{article.title}</a>
                  <br />
                </li>
              ))}
            </div>
          )
      )}
    </article>
  </>
);

export default Press;
